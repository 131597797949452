<template>
  <div class="stepCmpt">
    <!-- 货主 -->
    <div class="stepCmptWrap" v-if="role == 2">
      <div class="item" v-for="(item, index) in items1" :key="item.title">
        <span :class="activeList[index] == 1 ? 'leftActive': 'left'"></span>
        <div :class="activeList[index] == 1 ? 'middleActive': 'middle'">
          <div class="top">{{item.title}}</div>
          <div class="bottom">{{item.content}}</div>
        </div>
        <span :class="activeList[index] == 1 ? 'rightActive': 'right'"></span>
      </div>
    </div>
    <!-- 服务商 -->
    <div class="stepCmptWrap" v-if="role == 3">
      <div class="item" v-for="(item, index) in items2" :key="item.title">
        <span :class="activeList[index] == 1 ? 'leftActive': 'left'"></span>
        <div :class="activeList[index] == 1 ? 'middleActive': 'middle'">
          <div class="top">{{item.title}}</div>
          <div class="bottom">{{item.content}}</div>
        </div>
        <span :class="activeList[index] == 1 ? 'rightActive': 'right'"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeList: {
      type: Array,
      default: () => {
        return [1, 0, 0, 0]
      }
    },
    role: {
      type: Number,
      default: () => {
        return 2
      }
    }
  },
  data() {
    return {
      items1: [
        {
          title: '第一步 上传运单',
          content: '货主上传运单、并通过风控审核',
        },
        {
          title: '第二步 结算申请',
          content: '货主选择服务商、结算申请',
        },
        {
          title: '第三步 确认付款',
          content: '服务商同意开票、货主付款',
        },
        {
          title: '第四步 核对发票',
          content: '服务商上传发票、货主确认收票',
        }
      ],
      items2: [
        {
          title: '第一步 上传运单',
          content: '货主上传运单已通过风控审核',
        },
        {
          title: '第二步 结算申请',
          content: '货主已选择您作为开票服务商',
        },
        {
          title: '第三步 确认付款',
          content: '当前由您确认，能否满足开票要求',
        },
        {
          title: '第四步 核对发票',
          content: '由您上传发票信息，并将发票邮寄给货主',
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.stepCmpt {
  margin-bottom: 10px;
  .stepCmptWrap {
    display: flex;
    width: calc(100vw - 250px);
    height: 52px;
    position: relative;
    .item {
      display: flex;
      width: 25%;
      .left {
        content: '';
        width: 0;
        height: 0;
        border: 26px solid #f0f0f0;
        border-color: transparent transparent #fff transparent;
        transform: rotate(90deg);
        background: #ecf5ff;
      }
      .leftActive {
        content: '';
        width: 0;
        height: 0;
        border: 26px solid #3a8ee6;
        border-color: transparent transparent #fff transparent;
        transform: rotate(90deg);
        background: #3a8ee6;
      }
      .middle {
        min-width: 200px;
        color: #777;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: #ecf5ff;
        z-index: 1000;
        .top {
          font-size: 13px;
          font-weight: 600;
        }
        .bottom {
          font-size: 12px;
        }
      }
      .middleActive {
        min-width: 200px;
        color: #f8f8f8;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #3a8ee6 ;
        z-index: 1000;
        .top {
          font-size: 13px;
          font-weight: 600;
        }
        .bottom {
          font-size: 12px;
        }
      }
      .right {
        content: '';
        width: 0;
        height: 0;
        border: 26px solid #ecf5ff;
        border-color: transparent transparent #ecf5ff transparent;
        transform: rotate(90deg);
        right: 0;
        background: transparent;
        z-index: 999;
      }
      .rightActive {
        content: '';
        width: 0;
        height: 0;
        border: 26px solid #3a8ee6;
        border-color: transparent transparent #3a8ee6  transparent;
        transform: rotate(90deg);
        right: 0;
        background: transparent;
        z-index: 999;
      }
    }
  }
}
</style>